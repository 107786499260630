import { uploadImages, ObjectsTriggers, sourceImageHost } from './preCreateSpots'

export const addUploadFeature = requestHandler => async (type, resource, params) => {
  if (type === 'UPDATE' && resource === 'access_products') {
    const data = params.data;
    delete data.lastHistoryRecord
    delete data["lastHistoryRecord.id"]
    delete data.totalShortRentHours

    return requestHandler.update(resource, params);
  }

  if(type === 'CREATE' && resource === 'objects') {
    const newParams = await ObjectsTriggers.execute(params)

    return requestHandler.create(resource, newParams);
  }

  if(type === 'UPDATE' && resource === 'objects') {
    const newParams = await ObjectsTriggers.execute(params)

    return requestHandler.update(resource, newParams);
  }
  /**При Create сохраняем pictures одним запросом с objects*/
  // if (
  //   type === 'CREATE' && resource === 'objects' &&
  //   params.data.images && Array.isArray(params.data.images) && params.data.images.length > 0
  // ) {

  //   const { data: { images } } = await uploadImages(params.data.images);
  //   const newParams = {
  //     ...params,
  //     data: {
  //       ...params.data,
  //       pictures: {
  //         data: images.map(name => ({ name, url: sourceImageHost + name }))
  //       },
  //       images: images.join(',')
  //     },
  //   };
  //   return requestHandler(type, resource, newParams);
  // }

  /**При Update сохраняем pictures отдельным запросом */
  if (
    type === 'UPDATE' && resource === 'objects' &&
    params.data.images && Array.isArray(params.data.images) && params.data.images.length > 0
  ) {
    const { data: { images } } = await uploadImages(params.data.images);

    images.map(name => requestHandler.create('pictures', {
      data: { name, url: sourceImageHost + name, object_id: params.id }
    }))

    const newParams = {
      ...params,
      data: {
        ...params.data,
        images: images.join(',').toString()
      },
    };

    return requestHandler.update(resource, newParams);
  }

  if (type === 'CREATE') {
    return requestHandler.create(resource, params);
  }

  if (type === 'UPDATE') {
    return requestHandler.update(resource, params);
  }

  if (type === 'GET_LIST') {
    return requestHandler.getList(resource, params);
  }

  if (type === 'GET_MANY') {
    return requestHandler.getMany(resource, params);
  }

  if (type === 'GET_ONE') {
    return requestHandler.getOne(resource, params);
  }

  if (type === 'GET_MANY_REFERENCE') {
    return requestHandler.getManyReference(resource, params);
  }

  if (type === 'DELETE') {
    return requestHandler.delete(resource, params);
  }

  if (type === 'DELETE_MANY') {
    return requestHandler.deleteMany(resource, params);
  }
};
