import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Filter,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const AccessListFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput
      label="Парковка"
      source="objectId"
      reference="objects"
    >
      <AutocompleteInput
        filterToQuery={(text: string) => text && { title: text }}
        optionText="title"
        optionValue="id"
      />
    </ReferenceInput>

    <ReferenceInput
      label="Пользователь"
      source="userId"
      reference="users"
    >
      <AutocompleteInput
        filterToQuery={(text: string) => text && { "phone": text }}
        optionValue="id"
        optionText={(item: any) => (item || {}).phone ? `${item.phone}, ${item.full_name}` : '' }
      />
    </ReferenceInput>

    <ReferenceInput source="spotId" reference="object_spots" perPage={400}>
      <SelectInput optionText="number" />
    </ReferenceInput>
  </Filter>
);

export const AccessListList = (props: JSX.IntrinsicAttributes) => (
  <List
    {...props}
    filters={<AccessListFilter />}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="edit">
      <ReferenceField source="objectId" reference="objects">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="spotId" reference="object_spots">
        <TextField source="number" />
      </ReferenceField>
      <TextField source="carNumber" />
      <ReferenceField source="userId" reference="users">
        <TextField source="car_number" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users">
        <TextField source="phone" />
      </ReferenceField>
      <TextField source="fullName" />
      <TextField source="accessType" />
      <DateField source="rentStartedAt" showTime={true}/>
      <DateField source="rentFinishedAt" showTime={true}/>
    </Datagrid>
  </List>
);
