import axios from 'axios';
export const uploadImageHost = 'http://core.onepark.pro:1351/api/objects/upload-images';
export const sourceImageHost = 'http://core.onepark.pro/uploads/';

export const uploadImages = async (images) => {
  if (!Array.isArray(images)) return;
  const newPictures = images.filter(p => p.rawFile instanceof File);
  if (newPictures.length === 0) return;
  const formData = new FormData();
  for (let i = 0; i < newPictures.length; i++) {
    formData.append('images', newPictures[i].rawFile);
  }
  formData.append('images', newPictures);
  return await axios.post(uploadImageHost, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export class ObjectsTriggers {
  static async execute(params) {
		console.log('params', params)

    const preCreateSpotsParams = await ObjectsTriggers.preCreateSpots(params)
		console.log(preCreateSpotsParams)

    const preUploadPicturesParams = await ObjectsTriggers.preUploadPictures(params)




    return Object.assign({}, preCreateSpotsParams, preUploadPicturesParams)
  }

  static async preCreateSpots(params) {
    const { places_count = 1 } = params.data;
    const spots = [...new Array(places_count)].map((el, idx) => ({ number: idx + 1, spotType: 'LONG' }))
    params.data.object_spots = { data: spots }
    return Promise.resolve(params)
  }

  static async preUploadPictures(params) {
    const isAllow = params.data.images && params.data.images.length > 0 && typeof params.data.images !== 'string'
    if(!isAllow) return Promise.resolve({})

    const { data: { images } } = await uploadImages(params.data.images);
    const imagesData = images.map(name => ({ name, url: sourceImageHost + name }))
    const imagesStr = images.join(',')
    params.data.pictures = { data: imagesData }
    params.data.images = imagesStr
    return Promise.resolve(params)
  }
}
