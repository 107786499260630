import React from 'react';
import {
  Filter,
  SearchInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  DateField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';


const ObjectsFilter = props => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
    <ReferenceInput label="Manager" source="manager_id" reference="managers" perPage={400}>
      <AutocompleteInput optionText="email" optionValue="id" />
    </ReferenceInput>
  </Filter>
);

const ObjectList = props => (
  <List {...props} sort={{ field: 'created_at', order: 'DESC' }} filters={<ObjectsFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="country" />
      <TextField source="city" />
      <TextField source="street" />
      <NumberField source="places_count" />
      <TextField source="roof_type" />
      <BooleanField source="isUp" />
      <BooleanField source="is_on_map" />
      <BooleanField source="is_long_rent" />
      <BooleanField source="is_short_rent" />
      <BooleanField source="is_open_by_ble" />
      <BooleanField source="is_open_by_gsm" />
      <BooleanField source="is_places_not_assigned" label="Места не закреплены(Ваше место - любое)" />
      <TextField source="phone_entry" label="Номер телефона Въезд" />
      <TextField source="phone_departure" label="Номер телефона Выезд" />
      <TextField source="code_entry" label="Код Въезд" />
      <TextField source="code_departure" label="Код Выезд" />
      <DateField source="created_at" />
      {/* <TextField source="price_hour" />
       <TextField source="price_day" />
       <TextField source="price_month" /> */}
    </Datagrid>
  </List>
);

export default ObjectList;
