import React from 'react';
import {
  Edit,
  ReferenceManyField,
  SingleFieldList,
  required,
  ImageInput,
  ImageField,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';

const MyImageField = ({ record }) =>
  <img src={record.url} width="100" height="50" id={record.name} alt="картинка"/>;

const ObjectEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title"  validate={required()}/>
        <TextInput source="country" />
        <TextInput source="city" />
        <TextInput source="street" />
        <NumberInput source="places_count" />
        <TextInput source="roof_type" />
        <BooleanInput source="is_secure" />
        <BooleanInput source="is_video_on" />
        <BooleanInput source="is_warm" />
        <BooleanInput source="is_box" />
        <BooleanInput source="is_not_have_controller" />
        <TextInput source="parking_rules_link" />
        <TextInput source="price_hour" />
        <TextInput source="price_day" />
        <TextInput source="price_month" />
        <BooleanInput source="isUp" />
        <BooleanInput source="is_on_map" />
        <TextInput source="lat" />
        <TextInput source="lng" />
        <BooleanInput source="is_short_rent" label="Hourly rent" />
        <BooleanInput source="is_daily_rent" label="Daily rent" />
        <BooleanInput source="is_long_rent" label="Monthly rent" />
        <BooleanInput source="is_open_by_ble" />
        <BooleanInput source="is_open_by_gsm" />
        <BooleanInput source="is_places_not_assigned" label="Места не закреплены(Ваше место - любое)" />
        <TextInput source="free_time_minutes" />
        <TextInput source="exitTimeMinutes" />

        <ReferenceInput label="Owner" source="manager_id" reference="managers" perPage={400}>
          <AutocompleteInput optionText="email" optionValue="id"  />
        </ReferenceInput>

        <ReferenceManyField label="Изображения" reference="pictures" target="object_id">
          <SingleFieldList linkType="edit">
            <MyImageField />
          </SingleFieldList>
        </ReferenceManyField>

        <ImageInput source="images" label="Images" accept="image/*" multiple={true}>
          <ImageField source="src" />
        </ImageInput>

        <TextInput source="phone_entry" label="Номер телефона Въезд" />
        <TextInput source="phone_departure" label="Номер телефона Выезд" />
        <TextInput source="code_entry" label="Код Въезд" />
        <TextInput source="code_departure" label="Код Выезд" />
      </SimpleForm>
    </Edit>
  )
};

export default ObjectEdit;
