import React from "react";
import {
	Create,
	SimpleForm,
	DateTimeInput,
	ReferenceInput,
	SelectInput,
	required,
	AutocompleteInput,
	FormDataConsumer,
} from "react-admin";

const onChange = (e: any) => {
	console.log(e)
}
export const AccessListCreate = (props: JSX.IntrinsicAttributes) => (
	<Create {...props}>
		<SimpleForm>
			<ReferenceInput
				label="Парковка"
				source="objectId"
				reference="objects"
				onChange={onChange}
			>
				<AutocompleteInput
          filterToQuery={(text: string) => text && { title: text }}
					optionText="title"
					optionValue="id"
					validate={required()}
				/>
			</ReferenceInput>

			<ReferenceInput
				source="userId"
				reference="users"
			>
				<AutocompleteInput
          filterToQuery={(text: string) => text && { "phone": text }}
					optionValue="id"
          optionText={(item: any) => (item || {}).phone ? `${item.phone}, ${item.full_name}` : '' }
					validate={required()}
				/>
			</ReferenceInput>
			<FormDataConsumer>
				{({ formData, ...rest }: any) => {
					return (
						<ReferenceInput
							source="spotId"
							reference="object_spots"
							filter={{ objectId: formData.objectId }}
							perPage={600}
						>
							<SelectInput
								optionValue="id"
								optionText={({ number }: any) => [number].join(", ")}
								resettable
							/>
						</ReferenceInput>)
				}}
			</FormDataConsumer>
			<SelectInput
				source="accessType"
				defaultValue="LONG"
				validate={required()}
				choices={[
					{ id: "SHORT", name: "SHORT" },
					{ id: "LONG", name: "LONG" },
				]}
			/>
			<DateTimeInput source="rentStartedAt" />
			<DateTimeInput source="rentFinishedAt" />
		</SimpleForm>
	</Create>
);
