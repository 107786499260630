import { ShowGuesser, EditGuesser } from 'react-admin';
import { RaResourse } from '../../types';
import { UserCreate } from './usersCreate';
import UsersList from './users-list';

export default {
  show: ShowGuesser,
  list: UsersList,
  create: UserCreate,
  edit: EditGuesser,
} as RaResourse