import React from "react";

import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  AutocompleteInput,
  ReferenceInput,
  EditButton,
  BulkDeleteButton,
  Button,
  TextInput,
  useListContext,
  useUpdateMany,
} from "react-admin";


const SpotsFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput
      label="Парковка"
      source="objectId"
      reference="objects"
    >
      <AutocompleteInput
        filterToQuery={(text: string) => text && { title: text }}
        optionText="title"
        optionValue="id"
      />
    </ReferenceInput>

    <TextInput source="floor" label="Этаж" />
  </Filter>
);

const ToLong = (props: JSX.IntrinsicAttributes) => {
  const { selectedIds } = useListContext();
  const [updateMany, { isLoading }] = useUpdateMany();

  const handleClick = () => {
    updateMany('object_spots', { ids: selectedIds, data: { spotType: 'LONG' } });
  }

  return <Button
    label="Change to Long"
    onClick={handleClick}
    disabled={isLoading}
  />
}

const ToShort = (props: JSX.IntrinsicAttributes) => {
  const { selectedIds } = useListContext();
  const [updateMany, { isLoading }] = useUpdateMany();

  const handleClick = () => {
    updateMany('object_spots', { ids: selectedIds, data: { spotType: 'SHORT' } });
  }

  return <Button
    label="Change to Short"
    onClick={handleClick}
    disabled={isLoading}
  />
}

const PostBulkActionButtons = (props: JSX.IntrinsicAttributes) => (
  <>
    <ToLong  {...props} />
    <ToShort {...props} />
    {/* default bulk delete action */}
    <BulkDeleteButton />
  </>
);

export const SpotsList = (props: JSX.IntrinsicAttributes) => (
  <List
    {...props}
    filters={<SpotsFilter />}
    sort={{ field: "updatedAt", order: "DESC" }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={<PostBulkActionButtons {...props} />}>
      <TextField label="ID" source="id" />
      <ReferenceField label="Парковка" source="objectId" reference="objects"><TextField source="title" /></ReferenceField>
      <TextField label="Номер" source="number" />
      <TextField label="Тип" source="spotType" />


      {/*<SimpleShowLayout>*/}
      {/*  <TextField source="first_name" />*/}
      {/*  <TextField source="last_name" />*/}
      {/*  <ReferenceManyField label="Books" reference="books" target="author_id">*/}
      {/*    <Datagrid>*/}
      {/*      <TextField source="title" />*/}
      {/*      <TextField source="year" />*/}
      {/*    </Datagrid>*/}
      {/*  </ReferenceManyField>*/}
      {/*</SimpleShowLayout>*/}


      {/*<ReferenceOneField*/}
      {/*  label="Место занято"*/}
      {/*  source="id"*/}
      {/*  reference="access_list"*/}
      {/*  emptyText="Нет"*/}
      {/*  target="spotId"*/}
      {/*  sort={{ field: 'rentFinishedAt', order: 'DESC' }}*/}
      {/*  sortBy={'rentFinishedAt'}*/}
      {/*  sortable={false}*/}
      {/*>*/}
      {/*  <FunctionField*/}
      {/*    render={*/}
      {/*      (record: any) => (moment(record.rentFinishedAt).diff(moment(), 'hours') > 0*/}
      {/*        ? (record.rentFinishedAt ? "Да, до: " + moment(record.rentFinishedAt).format("YYYY-MM-DD hh:mm") : '')*/}
      {/*        : 'Нет')*/}
      {/*    }*/}
      {/*  />*/}
      {/*</ReferenceOneField>*/}

      <TextField label="Этаж" source="floor" emptyText="—" />
      <TextField label="Цена за месяц" source="price_month" emptyText="—" />
      <TextField label="Цена за день" source="price_day" emptyText="—" />
      <TextField label="Цена за час" source="price_hour" emptyText="—" />
      <TextField label="Дата обновления" source="updatedAt" />
      <EditButton />
    </Datagrid>
  </List>
);
