import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  ImageInput,
  ImageField,
  SelectInput,
  required,
} from 'react-admin';

const ObjectCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title"  validate={required()} />
      <TextInput source="country" />
      <TextInput source="city" />
      <TextInput source="street" />
      <NumberInput source="places_count" />

      <SelectInput
        source="roof_type"
        initialValue="cover"

        choices={[
          { id:'cover', name: 'Крытая' },
          { id:'open', name: 'Открытая' },
          { id:'sub', name: 'Подземная' },
        ]}
      />

      <BooleanInput source="is_secure" />
      <BooleanInput source="is_video_on" />
      <BooleanInput source="is_warm" />
      <BooleanInput source="is_box" />
      <BooleanInput source="is_not_have_controller" />
      <TextInput source="parking_rules_link" />
      <TextInput source="price_hour" />
      <TextInput source="price_day" />
      <TextInput source="price_month" />
      <BooleanInput source="isUp" initialValue={true} />
      <BooleanInput source="is_on_map" />
      <TextInput source="lat" />
      <TextInput source="lng" />
      <BooleanInput source="is_short_rent" />
      <BooleanInput source="is_long_rent" />
      <BooleanInput source="is_open_by_ble" />
      <BooleanInput source="is_open_by_gsm" />
      <BooleanInput source="is_places_not_assigned" label="Места не закреплены(Ваше место - любое)" />
      <TextInput source="free_time_minutes" />
      <TextInput source="exitTimeMinutes" />

      <ReferenceInput label="Owner" source="manager_id" reference="managers" perPage={400}>
        <AutocompleteInput optionText="email" optionValue="id" />
      </ReferenceInput>

      <ImageInput source="images" label="Images" accept="image/*" multiple={true}>
        <ImageField source="src" />
      </ImageInput>

      <TextInput source="phone_entry" label="Номер телефона Въезд" />
      <TextInput source="phone_departure" label="Номер телефона Выезд" />
      <TextInput source="code_entry" label="Код Въезд" />
      <TextInput source="code_departure" label="Код Выезд" />

      {/* <ReferenceManyField label="Места" reference="object_spots" target="objectId">
       <SingleFieldList>
       <ChipField source="number" />
       </SingleFieldList>
       </ReferenceManyField> */}
    </SimpleForm>
  </Create>
);

export default ObjectCreate;
