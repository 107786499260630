import ControllerList from './controllers-list';
import ControllerEdit from './controllers-edit';
import ControllerCreate from './controllers-create';
import { LockOpen } from '@material-ui/icons/';

export default {
    list: ControllerList,
    create: ControllerCreate,
    edit: ControllerEdit,
    icon: LockOpen,
};