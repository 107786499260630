import ManagerList from './managers-list';
import ManagerEdit from './managers-edit';
import ManagerCreate from './managers-create';
import ManagerShow from './managers-show';
import { PeopleAlt } from '@material-ui/icons/';

export default {
    list: ManagerList,
    create: ManagerCreate,
    edit: ManagerEdit,
    show: ManagerShow,
    icon: PeopleAlt,
};