import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';

export const UserCreate = (props: any): React.ReactElement => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source="phone" />
			<TextInput source="full_name" />
			<TextInput source="car_number" />
		</SimpleForm>
	</Create>
);
