import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	DateField,
	ReferenceField,
	Filter,
	AutocompleteInput,
	ReferenceInput,
} from 'react-admin';

const AccessProductsFilter = (props: any) => (
	<Filter {...props}>
		<ReferenceInput
			label="Парковка"
			source="object_id"
			reference="objects"
			filterToQuery={(text: string) => text && ({ "title": text })}
		>
			<AutocompleteInput optionText="title" optionValue="id" allowEmpty={true} />
		</ReferenceInput>
		<ReferenceInput
			label="Пользователь"
			source="user_id"
			reference="users"

		>
			<AutocompleteInput
        filterToQuery={(text: string) => text && ({ "phone": text })}
				optionValue="id"
        optionText={(item: any) => (item || {}).phone ? `${item.phone}, ${item.full_name}` : '' }
			/>
		</ReferenceInput>
	</Filter>
);

export const AccessProductList = (props: any) => (
	<List {...props} filters={<AccessProductsFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
		<Datagrid rowClick="edit">
			<TextField source="accessType" />
			<ReferenceField source="object_id" reference="objects">
				<TextField source="title" />
			</ReferenceField>
			<ReferenceField source="user_id" reference="users">
				<TextField source="phone" />
				{/* <TextField source="full_name" /> */}
			</ReferenceField>
			<TextField source="status" />
			<DateField source="rentStartedAt" showTime={true} />
			<DateField source="rentFinishedAt" showTime={true} />
		</Datagrid>
	</List>
);
