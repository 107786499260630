import React from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	DateTimeInput,
	ReferenceInput,
	SelectInput,
	FormDataConsumer,
} from "react-admin";

export const AccessListEdit = (props: JSX.IntrinsicAttributes) => (
	<Edit {...props}>
		<SimpleForm>
			<ReferenceInput source="objectId" reference="objects" disabled perPage={400}>
				<SelectInput optionText="title" />
			</ReferenceInput>
			<ReferenceInput source="userId" reference="users" disabled perPage={400}>
				<SelectInput optionText="phone" />
			</ReferenceInput>

			<FormDataConsumer>
				{({ formData, ...rest }: any) => {
					return (
						<ReferenceInput
							source="spotId"
							reference="object_spots"
							filter={{ objectId: formData.objectId }}
							perPage={400}
						>
							<SelectInput
								optionValue="id"
								optionText={({ number }: any) => [number].join(", ")}
								resettable
							/>
						</ReferenceInput>
					);
				}}
			</FormDataConsumer>
			<TextInput source="accessType" />
			<DateTimeInput source="rentStartedAt" />
			<DateTimeInput source="rentFinishedAt" />
		</SimpleForm>
	</Edit>
);
