import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
} from 'react-admin';


export const SpotsEdit = (props: JSX.IntrinsicAttributes) => {
  return <Edit { ...props }>
    <SimpleForm>
      <NumberInput source="number"/>
      <ReferenceInput source="objectId" reference="objects" perPage={400}><SelectInput optionText="id"/></ReferenceInput>

      <SelectInput
        source="spotType"

        choices={ [
          {id: 'LONG', name: 'LONG'},
          {id: 'SHORT', name: 'SHORT'},
        ] }
      />

      <NumberInput source="price_month" step={ 0.01 }/>
      <NumberInput source="price_day" step={ 0.01 }/>
      <NumberInput source="price_hour" step={ 0.01 }/>
      <NumberInput source="floor" step={ 1 }/>
    </SimpleForm>
  </Edit>
}
