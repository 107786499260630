import React from 'react';
import {
	Create,
	SimpleForm,
	DateInput,
	ReferenceInput,
	SelectInput,
	AutocompleteInput,
	required,
} from 'react-admin';

export const AccessProductsCreate = (props: any): React.ReactElement => (
	<Create {...props}>
		<SimpleForm >
			<SelectInput
				source="accessType"
				defaultValue="LONG"
				validate={required()}
				choices={[
					{ id: 'SHORT', name: 'SHORT' },
					{ id: 'LONG', name: 'LONG' },
				]}
			/>
			<ReferenceInput
				label="Парковка"
				source="object_id"
				reference="objects"
			>
				<AutocompleteInput optionText="title" optionValue="id" filterToQuery={(text: string) => text && ({ "title": text })} />
			</ReferenceInput>
			<ReferenceInput
				source="user_id"
				reference="users"
			>
				<AutocompleteInput
          filterToQuery={(text: string) => text && ({ "phone": text })}
					optionValue="id"
          optionText={(item: any) => (item || {}).phone ? `${item.phone}, ${item.full_name}` : '' }
					validate={required()} />
			</ReferenceInput>
			<DateInput source="rentFinishedAt" validate={required()} />
			<DateInput source="rentStartedAt" defaultValue={new Date()} validate={required()} />
		</SimpleForm>
	</Create>
);
