import React, { useEffect }  from 'react';
import { Admin, Resource } from 'react-admin';
import objects from './pages/objects';
import managers from './pages/managers';
import controllers from './pages/controllers';
import pictures from './pages/pictures';
import users from './pages/users';
import payments from './pages/payments';
import accessProducts from './pages/accessProducts';
import authProvider from './authProvider';
import buildHasuraProvider from 'ra-data-hasura';
import accessList from './pages/accessList';
import spots from './pages/spots';
import { ApolloClient, InMemoryCache, ApolloLink, from } from '@apollo/client';
import { addUploadFeature } from "./helpers/uploadFiles";
import { HttpLink } from "apollo-boost";

const logLink = new ApolloLink((operation, forward) => {
  // console.log(`GraphQL Request: ${operation.operationName}`, operation);
  return forward(operation).map((response) => {
    // console.log(`GraphQL Response for ${operation.operationName}:`, response);
    return response;
  });
});

const httpLink = new HttpLink({
  uri: 'http://core.onepark.pro:8000/v1/graphql',
  headers: {
    'x-hasura-admin-secret': 'snW8u5xem540Q9v9W5vEA6FXyU',
  },
});

// @ts-ignore
const link = from([logLink, httpLink]);

const myClientWithAuth = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  headers: {
    'x-hasura-admin-secret': `snW8u5xem540Q9v9W5vEA6FXyU`,
    // 'Authorization': `Bearer xxxx`,
  },
});

const App = (): React.ReactElement => {
  const [dataProvider, setDataProvider] = React.useState<Function | null>(null);

  useEffect(() => {
    const buildDataProvider = async () => {
      try {
        const dataProvider = addUploadFeature(await buildHasuraProvider({ client: myClientWithAuth }));

        const customDataProvider = (type: string, resource: string, params: any) => {
          return dataProvider(type, resource, params)
            .then(response => {
              if (!response || Object.keys(response).length === 0) {
                console.error(`No data returned for '${type}' operation on resource '${resource}'`, params);
                throw new Error(`No data returned for '${type}' operation on resource '${resource}'`);
              }
              return response;
            })
            .catch(error => {
              console.error(`Error processing '${type}' operation on resource '${resource}'`, params);
              console.error(error);
              throw new Error(`Custom data provider error: ${error.message}`);
            });
        };

        setDataProvider(() => customDataProvider);
      } catch (error) {
        console.error("Error building the data provider:", error);
      }
    };

    buildDataProvider();
  }, []);

  if (!dataProvider) {
    return <div>Loading</div>;
  }

  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="objects" {...objects} />
      <Resource name="controllers" {...controllers} />
      <Resource name="managers" {...managers} />
      <Resource name="pictures" {...pictures} />
      <Resource name="access_list" {...accessList} />
      <Resource name="access_products" {...accessProducts} />
      <Resource name="users" {...users} />
      <Resource name="payments" {...payments} />
      {/* <Resource name="cameras" {...users} /> */}
      <Resource name="object_spots" {...spots} />
    </Admin>
  );
};

export default App;
