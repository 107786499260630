import React from "react";
import {
	List,
	Datagrid,
	TextField,
	DateField,
	ReferenceField,
	Filter,
	AutocompleteInput,
	ReferenceInput,
} from "react-admin";

const PaymentsFilter = (props: any) => (
	<Filter {...props}>
		<ReferenceInput
			label="Парковка"
			source="objectId"
			reference="objects"
		>
			<AutocompleteInput
				optionText="title"
				optionValue="id"
				allowEmpty={true}
        filterToQuery={(text: string) => text && { title: text }}
			/>
		</ReferenceInput>
		<ReferenceInput
			label="Пользователь"
			source="userId"
			reference="users"
		>
			<AutocompleteInput
        filterToQuery={(text: string) => text && { "phone": text }}
				optionValue="id"
        optionText={(item: any) => (item || {}).phone ? `${item.phone}, ${item.full_name}` : '' }
			/>
		</ReferenceInput>
	</Filter>
);

export const PaymentsList = (props: JSX.IntrinsicAttributes) => (
	<List
		{...props}
		filters={<PaymentsFilter />}
		sort={{ field: "createdAt", order: "DESC" }}
	>
		<Datagrid>
			<DateField label="Дата" source="createdAt" />
			<ReferenceField label="Парковка" source="objectId" reference="objects"><TextField source="title" /></ReferenceField>
			<ReferenceField label="Телефон" source="userId" reference="users"><TextField source="phone" /></ReferenceField>
			<ReferenceField label="Имя пользователя" source="userId" reference="users"><TextField source="full_name" /></ReferenceField>
			<TextField label="Сумма" source="amount" />
			<ReferenceField label="Тип аренды" source="rentId" reference="access_products"><TextField source="accessType" /></ReferenceField>
			<ReferenceField label="Дата начала аренды" source="rentId" reference="access_products"><DateField source="rentStartedAt" /></ReferenceField>
			<ReferenceField label="Дата окончания аренды" source="rentId" reference="access_products"><DateField source="rentFinishedAt" /></ReferenceField>
			<TextField source="transactionId" />
	</Datagrid>
	</List>
);
