import React from 'react';
import { Edit, SimpleForm, TextInput, ImageField } from 'react-admin';

export default (props) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="name" />
			<ImageField source="url" />
		</SimpleForm>
	</Edit>
);
