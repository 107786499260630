import ObjectEdit from './objects-edit';
import ObjectList from './objects-list';
import ObjectShow from './objects-show';
import ObjectCreate from './objects-create';
import { Room } from '@material-ui/icons/';

export default {
    list: ObjectList,
    create: ObjectCreate,
    edit: ObjectEdit,
    show: ObjectShow,
    icon: Room,
};

