import React from 'react';
import {
  Filter,
  SearchInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

const ObjectsFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Пользователь по ID"
      source="id"
      reference="users"
    >
      <AutocompleteInput
        filterToQuery={(text) => text && { id: text }}
        optionText="id"
        optionValue="id"
        allowEmpty={true}
      />
    </ReferenceInput>

    <ReferenceInput
      label="Телефон"
      source="phone"
      reference="users"
    >
      <AutocompleteInput
        filterToQuery={(text) => text && { phone: text }}
        optionText="phone"
        optionValue="phone"
        allowEmpty={true}
      />
    </ReferenceInput>

    <SearchInput source="car_number" alwaysOn />
  </Filter>
);

const ObjectList = props => (
  <List {...props} sort={{ field: 'created_at', order: 'DESC' }} filters={<ObjectsFilter />}>
    <Datagrid rowClick="edit">
      <NumberField source="car_number" />
      <DateField source="created_at" />
      <TextField source="full_name" />
      <NumberField source="id" />
      <TextField source="phone" />
      <DateField source="updated_at" />
      <TextField source="email" />
      <TextField source="deleted_at" />
    </Datagrid>
  </List>
);

export default ObjectList;
