import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, ReferenceInput, AutocompleteInput } from 'react-admin';

const ControllerEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="name" />
			<TextInput source="mac_address" />
			<TextInput source="phone" />
			<TextInput source="label" />
			<BooleanInput source="in" />
			<BooleanInput source="out" />
			<ReferenceInput
				label="Owner"
				source="object_id"
				reference="objects"
			>
				<AutocompleteInput
					allowEmpty={true}
					optionValue="id"
					optionText="title"
          filterToQuery={(text) => text && ({ "title": text })}
				/>
			</ReferenceInput>
		</SimpleForm>
	</Edit>
);

export default ControllerEdit;
