import React from 'react';
import { List, Datagrid, DateField, RichTextField, EditButton, ReferenceField, TextField } from 'react-admin';

const ManagerList = props => (
	<List {...props} sort={{ field: 'created_at', order: 'DESC' }}>
		<Datagrid rowClick={"show"}>
			<TextField source="email" />
			<RichTextField source="description" />
			<TextField source="company" />
			<ReferenceField label="Owner" source="ownerId" reference="managers">
				<TextField source="email" />
			</ReferenceField>
			<DateField source="created_at" />
			<EditButton />
		</Datagrid>
	</List>
);

export default ManagerList;