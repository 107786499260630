import React from 'react';
import { List, Datagrid, ReferenceField, BooleanField, TextField, DateField } from 'react-admin';

const ControllerList = props => (
    <List {...props} sort={{ field: 'createdat', order: 'DESC' }}>
        <Datagrid rowClick="edit" >
            <ReferenceField source="object_id" reference="objects">
                <TextField source="title" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="label" />
            <TextField source="mac_address" />
            <BooleanField source="in" />
            <BooleanField source="out" />
            <DateField source="createdat" />
        </Datagrid>
    </List>
);

export default ControllerList;