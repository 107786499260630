import { ShowGuesser } from 'react-admin';
import { RaResourse } from '../../types';
import { AccessProductsCreate } from './accessProductsCreate';
import { AccessProductsEdit } from './accessProductsEdit';
import { AccessProductList } from './accessProductsList';

export default {
  show: ShowGuesser,
  list: AccessProductList,
  create: AccessProductsCreate,
  edit: AccessProductsEdit,
} as RaResourse