import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
  TextInput,
} from 'react-admin';

export const SpotsCreate = (props: JSX.IntrinsicAttributes) => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput source="number" />
      <ReferenceInput source="objectId" reference="objects" perPage={400}><SelectInput optionText="id" /></ReferenceInput>
      <TextInput source="spotType" />
    </SimpleForm>
  </Create>
);
