import { ShowGuesser } from 'react-admin';
import { RaResourse } from '../../types';
import { AccessListList } from './accessListList'
import { AccessListEdit } from './accessListEdit'
import { AccessListCreate } from './accessListCreate'

export default {
  show: ShowGuesser,
  list: AccessListList,
  create: AccessListCreate,
  edit: AccessListEdit,
} as RaResourse