import { ShowGuesser } from 'react-admin';
import { RaResourse } from '../../types';
import { SpotsCreate } from './spotsCreate'
import { SpotsEdit } from './spotsEdit'
import { SpotsList } from './spotsList'

export default {
  show: ShowGuesser,
  list: SpotsList,
  create: SpotsCreate,
  edit: SpotsEdit,
} as RaResourse