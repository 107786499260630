import React from 'react';
import {
	Show, SimpleShowLayout, TextField, RichTextField,
	ReferenceManyField, SingleFieldList, ChipField, 
	NumberField, ReferenceField, 
} from 'react-admin';

const MyField = ({ record = {}, source }) =>
	<span >
		{record[source]}
	</span>;

const ManagerShow = props => {
	console.log(props)
	return (
		<Show title={<MyField source="email" />}  {...props}>
			<SimpleShowLayout>
				<TextField source="email" />
				<RichTextField source="password" />
				<NumberField source="addUserLimitDay" />
				<ReferenceField label="Owner" source="ownerId" reference="managers">
					<TextField source="email" />
				</ReferenceField>
				<RichTextField source="description" />
				<ReferenceManyField label="Объекты" reference="objects" target="manager_id">
					<SingleFieldList>
						<ChipField source="street" />
					</SingleFieldList>
				</ReferenceManyField>
			</SimpleShowLayout>
		</Show>
	)
};

export default ManagerShow;