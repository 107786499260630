import React from 'react';
import { Create, SimpleForm, TextInput, required, NumberInput, ReferenceInput, AutocompleteInput } from 'react-admin';

const ManagerCreate = props => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source="email" validate={required()}/>
			<TextInput source="password" validate={required()}/>
			<TextInput source="company" />
			<NumberInput source="addUserLimitDay" />
			<ReferenceInput label="Owner" source="ownerId" reference="managers" perPage={400}>
				<AutocompleteInput optionText="email" optionValue="id" />
			</ReferenceInput>
			<TextInput multiline source="description" />
		</SimpleForm>
	</Create>
);

export default ManagerCreate;
