import React from 'react';
import {
    Show,
    TabbedShowLayout,
    RichTextField,
    Tab,
    TabbedShowLayoutTabs,
    TextField,
    NumberField,
    BooleanField,
    ReferenceField,
    ArrayField,
    Datagrid,
    DateField
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

const Title = ({ record }) => {
    const { country, city, street } = record;
    return <span>Object {record ? `"${[country, city, street].join(' ')}"` : ''}</span>;
};

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="h6">Post details</Typography>
        <Typography variant="body2">
            Posts will only be published one an editor approves them
        </Typography>
    </div>
);

export const ObjectShow = props => (
    <Show title={<Title />} aside={<Aside />} {...props}>
        <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} /> }>
            <Tab label="Инфо">
                {/* <TextField source="id" /> */}
                <TextField source="country" />
                <TextField source="city" />
                <TextField source="street" />
                <NumberField source="places_count" />
                <TextField source="roof_type" />
                <BooleanField source="is_secure" />
                <BooleanField source="is_video_on" />
                <BooleanField source="isWarm" />
                <BooleanField source="is_box" />
                <BooleanField source="is_not_have_controller" />
                <TextField source="parking_rules_link" />
                <TextField source="price_hour" />
                <TextField source="price_day" />
                <TextField source="price_month" />
                {/* <TextField source="lat" />
                <TextField source="lng" /> */}
                <ReferenceField source="manager_id" reference="managers">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
            <Tab label="Отзывы">
            <ArrayField source="reviews">
                <Datagrid>
                    {/* <TextField source="id" /> */}
                    <TextField source="name" />
                    <NumberField source="rate" />
                    <RichTextField source="message" />
                    <DateField source="createdAt" />
                </Datagrid>
            </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ObjectShow;
